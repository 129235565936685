import React from "react";
import Typed from "typed.js";
import ProjectCard from "./Cards/ProjectCard";
import Projects from "../DataLocalStorage/Projectos.json";

function Home() {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<i> Developer.<i>",
        "<i> Rocketeer.<i>",
        "<i> Enthusiast.<i>",
        "<i> Dreamer.<i>",
      ],
      typeSpeed: 90,
      backSpeed: 50,
      backDelay: 900,
      loop: true,
      loopCount: Infinity,
      smartBackspace: false,
      showCursor: true,
      cursorChar: "|", //no se si se supone que deberia de ir la ,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <div>
      <div className="principal">
        <div className="identity">
          <p>Hi, My name is Jeremías</p>
        </div>
        <div className="presentation">
          <p>I'm a</p>
          <span className="typewriterText" ref={el} />
        </div>
        <div className="bio">
          <p>
            Front-End developer and occasionally a crazy guy who launches{" "}
            <a
              href="/hobbies"
              className="experimental-rocketry-a"
              rel="noopener noreferrer"
            >
              experimental rockets
            </a>
            .
          </p>
          <p>Welcome to my world!</p>
        </div>
      </div>

      <div className="secondaryContainer">
        <div className="secondaryDiv">
          <h1>PROJECTS</h1>
          <ul>
            {Projects.map((Projects) => (
              <ProjectCard key={Projects.id} Project={Projects} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
