import React from "react";

function Footer() {
  return (
    <footer className="m-0 py-3 footerCustom">
      &copy; {new Date().getFullYear()} JEREMIAS
      <div className="lighthouse-stats">
        <div className="stats">
          <span className="number-lighthouse">100</span>
        </div>
        <div className="stats">
          <span className="number-lighthouse">100</span>
        </div>
        <div className="stats">
          <span className="number-lighthouse">100</span>
        </div>
        <div className="stats">
          <span className="number-lighthouse">100</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
